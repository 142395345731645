import InlineSpinner from 'client/js/util/inline_spinner';
import { FaIcon, HelpQuestionMark } from 'client/js/util/layout_utils';
import PropTypes from "prop-types";

const InfoText = function(props) {
  if(props.status == "available")
    return <div className="status"><FaIcon name="ok-circle" /> verfügbar</div>

  if(props.status == "premium-available")
    return <div><span className="status"><FaIcon name="ok-circle" /> verfügbar</span> <span className="badge badge-primary">Premium-Domain</span></div>

  if(props.status == "error")
    return <span className="status">Fehler bei der Prüfung</span>

  if(props.status == "unavailable")
    return <div>
             <span className="status">belegt: Umzug möglich</span>
             <HelpQuestionMark title="Domain belegt" content="Ein Umzug der eigenen Domain ist nur möglich, wenn dir die Domain bereits gehört und du sie bei einem anderen Anbieter registriert hast. Für den Umzug wird von deinem bisherigen Anbieter ein so genannter AuthCode generiert, den wir beim Umzugsprozess übermitteln, um nachzuweisen, dass der Umzug autorisiert ist." />
           </div>

  if(props.status == "premium-unavailable")
    return <div>
             <span className="status">belegt: Umzug möglich</span>
             <HelpQuestionMark title="Domain belegt" content="Ein Umzug der eigenen Domain ist nur möglich, wenn dir die Domain bereits gehört und du sie bei einem anderen Anbieter registriert hast. Für den Umzug wird von deinem bisherigen Anbieter ein so genannter AuthCode generiert, den wir beim Umzugsprozess übermitteln, um nachzuweisen, dass der Umzug autorisiert ist." />
             <span className="badge badge-primary">Premium-Domain</span>
           </div>

  if(props.status == "rgp")
    return <div>
             <span className="status">gelöscht: in der RGP</span>
             <HelpQuestionMark title="gelöscht: in der RGP" content="Diese Domain wurde gelöscht und befindet sich in der 'Redemption Grace Period'. Um die Domain wiederherzustellen kontaktiere bitte den Support (support@lima-city.de)." />
           </div>

  if(I18n_domain_check.errors[props.status])
    return <span className="status">{I18n_domain_check.errors[props.status]}</span>;

  if(props.status == "with-us")
    return <div>
             <span className="status">bereits bei lima-city</span>
             <HelpQuestionMark title="bereits registriert" content="Diese Domain ist bereits bei lima-city registriert. Um eine Verlängerung Deiner eigenen Domain durchzuführen, logge Dich bitte in die Verwaltung ein und verlängere die Domain über den dort angegeben Link. Bei Fragen kontaktiere bitte den Support (support@lima-city.de)." />
           </div>

  if(props.status)
    return <span>unbekannter Status &quot;{props.status}&quot;</span>;

  return <InlineSpinner text={false} />;
}

class AddToCart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {working: false};

    this.addToCart = this.addToCart.bind(this);
  }

  addToCart(e) {
    e.preventDefault();
    this.setState({working: true});
    let item_data = { item: 'domain',
                      tld: this.props.tld,
                      sld: this.props.sld,
                      promotion_id: this.props.promotion_id,
                      quantity: this.props.default_quantity }

    if(this.props.subscription_id && (this.props.status == 'available' || this.props.status == 'unavailable'))
      item_data['subscription_id'] = this.props.subscription_id;

    $.ajax(
      "/cart/add_item.json", { data: item_data, dataType: 'json' },
    ).done((data) => {
      this.setState({working: false})
      this.props.updateCartCb(data);
    }).error((xhr) => {
      this.setState({working: false})

      if(xhr.status == 400) {
        const obj = JSON.parse(xhr.responseText);

        if(obj?.errors?.base) {
          const error_message = obj.errors.base.join(', ');

          alert(error_message);
        }
      }
    });
  }

  render() {
    if(this.state.working)
      return <div className="cart-button">
              <a className="btn btn-primary disabled" onClick={(e) => e.preventDefault()} href={`/cart/add_item?item=domain&amp;tld=${this.props.tld}&amp;promotion_id=&amp;sld=${this.props.unicode_sld}&amp;quantity=`} aria-label="arbeitet..." role="button"><FaIcon name="spin fa-spinner" /></a>
            </div>;

    if(this.props.in_cart || this.state.added_to_cart)
      return <div className="cart-button">
              <a className="btn btn-secondary disabled" onClick={(e) => e.preventDefault()} href={`/cart/add_item?item=domain&amp;tld=${this.props.tld}&amp;promotion_id=&amp;sld=${this.props.unicode_sld}&amp;quantity=`} aria-label="aus Warenkorb entfernen" role="button"><FaIcon name="shopping-cart" /><sup><FaIcon name="check" /></sup></a>
            </div>;

    if(this.props.status == 'available' || this.props.status == 'unavailable' ||
       this.props.status == 'premium-available' || this.props.status == 'premium-unavailable')
      return <div className="cart-button">
              <a className="btn btn-primary" onClick={this.addToCart} href={`/cart/add_item?item=domain&amp;tld=${this.props.tld}&amp;promotion_id=&amp;sld=${this.props.unicode_sld}&amp;quantity=`} aria-label="zum Warenkorb hinzufügen" role="button"><FaIcon name="shopping-cart" /><sup><FaIcon name="plus" /></sup></a>
            </div>;

    return null;
  }
}

AddToCart.propTypes = {
  updateCartCb: PropTypes.func.isRequired,
  tld: PropTypes.string.isRequired,
  sld: PropTypes.string.isRequired,
  default_quantity: PropTypes.number,
  promotion_id: PropTypes.number,
  subscription_id: PropTypes.number,
  status: PropTypes.oneOf(['available', 'premium-available', 'premium-unavailable', 'unavailable',
                           'invalid-hostname', 'invalid-tld', 'registry-policy', 'invalid-length',
                           'idn-unsupported', 'with-us', 'rgp'])
};

const DomainPrice = function(props) {
  if(props.status == 'premium-available' || props.status == 'premium-unavailable')
    return <div className="domain-price"><span className="actual-price">{format_fractional_price(props.premium_price)}</span>/Jahr</div>

  if(props.status != 'available' && props.status != 'unavailable')
    return null;

  if((props.inclusive_remaining && !props.in_cart) || props.in_cart_as_inclusive)
    return <div className="domain-price">Inklusiv-Domain<sup>2</sup> <span className="actual-price old-price">{format_price(props.default_price*100)}/Jahr</span></div>

  if(props.promotion && props.promotion.id) {
    let promo_price = (props.status == 'available' ? props.promotion.create : props.promotion.transfer) * 100;

    return <div>
            <div className="promo-price">
              {(props.promotion.renew * 100) != promo_price ?
                <div>
                  <span className="p">1 Jahr für <strong>{format_price(promo_price)}</strong></span>,
                  <br />
                  <span className="p different-renew-price">danach {format_price(props.promotion.renew*100)}/Jahr</span>
                </div> :
                <span className="p"><strong>{format_price(promo_price)}/Jahr</strong></span>}
            </div>
            <div className="old-price">{format_price(props.default_price*100)}</div>
           </div>
  }

  if(props.default_price)
    return <div className="domain-price"><span className="actual-price">{format_price(props.default_price*100)}</span>/Jahr</div>

  return <div className="domain-price">nicht verfügbar</div>
}

const ResultRow = function(props) {
  let promotion = props.promotions && props.promotions.length > 0 ? props.promotions[0] : {};

  return <tr className={`tld_check_row ${props.checked ? 'checked' : 'unchecked'} checked_${props.status}`}>
           <td>
            <span className="domain">
              <span className="sld">{props.unicode_sld || props.sld}</span>.<span className="tld">{props.tld}</span>
            </span>

            {props.special_terms && props.special_terms != "" && <HelpQuestionMark icon_name="exclamation-circle special-terms" title="spezielle Bedingungen" content={props.special_terms} />}

            {promotion && <span className="promotion-text label label-info">{promotion.name}</span>}

            </td>
            <td className="av">
            <InfoText status={props.status} />
            </td>
            <td className="price-col">
              <DomainPrice promotion={promotion} status={props.status}
                           default_price={props.default_price}
                           inclusive_remaining={props.inclusive_remaining}
                           in_cart_as_inclusive={props.in_cart_as_inclusive}
                           subscription_id={props.subscription_id}
                           in_cart={props.in_cart}
                           premium_price={props.premium_price} />
            </td>
            <td>
              <AddToCart tld={props.tld} sld={props.unicode_sld} promotion_id={promotion.id} subscription_id={props.inclusive_remaining > 0 ? props.subscription_id : null} default_quantity={promotion.default_quantity} in_cart={props.in_cart} updateCartCb={props.updateCartCb} status={props.status} />
            </td>
         </tr>;
}

ResultRow.propTypes = {
  tld: PropTypes.string.isRequired,
  sld: PropTypes.string.isRequired,
  updateCartCb: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  in_cart: PropTypes.bool.isRequired,
  in_cart_as_inclusive: PropTypes.bool.isRequired,
  subscription_id: PropTypes.number
};

export default ResultRow;
