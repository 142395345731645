import PropTypes from "prop-types";
//import ResizeTab from 'client/js/usercp/cloud/virtual_machines/resize_tab';
import { FaIcon } from 'client/js/util/layout_utils';
import ResultRow from './result_row';
import SideCart from './side_cart';
import {CartItemCountPortal} from 'client/js/cart/app';

const sanitize_sld = (sld) => (sld.replace(/^www\./, '').replace(/\s+/g, '-').replace(/^-+/, '').replace(/-+$/, ''));

const split_fqdn = (fqdn, valid_tlds) => {
  valid_tlds.sort((a, b) => {
    let a_dots = (a.match(/\./g) || []).length, b_dots = (b.match(/\./g) || []).length;

    if(a_dots < b_dots)
      return 1;
    else if (a_dots > b_dots)
      return -1;

    return 0;
  });

  for (let tld of valid_tlds) {
    if(fqdn.endsWith(`.${tld}`)) {
      return {sld: fqdn.substring(0, (fqdn.length - tld.length - 1)), tld: tld};
    }
  }

  if(fqdn.indexOf('.') === -1)
    return {sld: fqdn, tld: "de"};

  let parts = fqdn.split('.');
  let sld = parts[0];
  parts.splice(0, 1);

  return {sld: sld, tld: parts.join('.')};
};

//data-disable-with="Okay, Captain!"
const DomainInput = function(props) {
  return <form className="simple_form form-domain-check justify-content-center mb-4 form-inline" id="domain_check_form" method="post" onSubmit={props.runSearch}>
      <div className={props.multi_line ? "multi-search-wrapper multi" : "multi-search-wrapper"}>
        {!props.multi_line ?
          <input required className="string required form-control" maxLength="63" placeholder="meine-domain.de" size="63" value={props.searchText} onChange={props.changeSearchText} type="text" /> :
          <textarea name="domains" className="form-control" value={props.searchText} onChange={props.changeSearchText} placeholder="mustermann.de foobar.de"></textarea>}

        <a href="#" onClick={props.toggleMultiLine}>{props.multi_line ? 'Einfachsuche' : 'Mehrfachsuche'}</a>
      </div>
      &nbsp;
      <button className="btn btn-success btn-lg" name="button" type="submit">
        <FaIcon name="search" /> jetzt prüfen
      </button>
    </form>;
}

class DomainInputContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {multi_line: false, searchText: props.initial_sld || 'mustermann', results: {}}

    this.changeSearchText = this.changeSearchText.bind(this);
    this.runSearch = this.runSearch.bind(this);
    this.toggleMultiLine = this.toggleMultiLine.bind(this);
  }

  runSearch(e) {
    e.preventDefault();
    this.props.runSearch(this.state.searchText);
  }

  changeSearchText(e) {
    this.setState({searchText: e.target.value});
  }

  toggleMultiLine() {
    this.setState((prevState) => {
      if(prevState.multi_line && prevState.searchText.trim().length > 0) {
        let lines = prevState.searchText.split("\n").map((l) => (l.toLowerCase().trim()));
        return {multi_line: !prevState.multi_line, searchText: lines[0] || ""};
      } else {
        return {multi_line: !prevState.multi_line};
      }
    });
  }

  render() {
    return <DomainInput searchText={this.state.searchText} multi_line={this.state.multi_line} changeSearchText={this.changeSearchText} toggleMultiLine={this.toggleMultiLine} runSearch={this.runSearch} />;
  }
}

class TabPaneContainer extends React.Component {
  constructor(props) {
    super(props);

    if(props.tabs) {
      this.state = {activeTab: Object.keys(props.tabs)[0]}
    } else {
      this.state = {activeTab: null}
    }

    this.onChange = this.onChange.bind(this);
  }

  onChange(new_tab, new_tlds, e) {
    e.preventDefault();
    this.setState({activeTab: new_tab});
    this.props.onChange(new_tlds)
  }

  render() {
    return <TabPane onChange={this.onChange} activeTab={this.state.activeTab} tabs={this.props.tabs} />;
  }
}

const TabPane = function(props) {
  const keys = Object.keys(props.tabs);

  return <ul className="nav nav-pills mb-3">
          {keys.map((key, index) => (<li key={index} className='nav-item'><a href='#' className={props.activeTab == key ? 'nav-link active' : 'nav-link'} onClick={(e) => props.onChange(key, props.tabs[key], e)}>{key}</a></li>))}
        </ul>;
}

const MoreDomains = function(props) {
  if(props.more_domains.length == 0)
    return null;

  return <p className="domain-check-more"><a href='#' onClick={props.loadMoreDomains}>mehr Domains anzeigen ({props.more_domains.length})</a></p>;
}

MoreDomains.propTypes = {
  loadMoreDomains: PropTypes.func.isRequired,
  more_domains: PropTypes.array.isRequired
}

const ResultsList = function(props) {
  if(props.searchList == null)
    return null;

  var safe_results = props.results || {};
  var inclusive_tlds = props.inclusive_tlds || [];
  let tlds = Object.keys(props.prices);

  return <table className="table table-striped table-domain-check">
          <thead>
              <tr><th>Domain</th><th>Status</th><th>Preis<sup>1</sup></th><th></th></tr>
            </thead>
            <tbody>
             {props.searchList.map((name) => {
                let item = safe_results[name];
                let inclusive_remaining = 0;
                let subscription_id = null;
                let {sld, tld} = split_fqdn(name, tlds);

                if(item && props.inclusive_remaining && inclusive_tlds.includes(item.tld) && props.inclusive_remaining > 0) {
                  subscription_id = props.subscription_id;
                  inclusive_remaining = props.inclusive_remaining;
                }

               return <ResultRow key={name} in_cart={props.cart_domains.includes(name) || (item && props.cart_domains.includes(`${item.unicode_sld}.${item.tld}`))} in_cart_as_inclusive={props.inclusive_domain_list.includes(name) || (item && props.inclusive_domain_list.includes(`${item.unicode_sld}.${item.tld}`))} inclusive_remaining={inclusive_remaining} inclusive_domains_used={props.inclusive_domains_used} subscription_id={subscription_id} text={name} checked={!!item} updateCartCb={props.updateCartCb} tld={tld} sld={sld} {...item} />
              })}
            </tbody>
        </table>;
}

class DomainCheckContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {cart_items: null, cart_domains: [], search_tlds: [], results: {}, more_domains: []};

    this.tabChange = this.tabChange.bind(this);
    this.runSearch = this.runSearch.bind(this);
    this.mergeResults = this.mergeResults.bind(this);
    this.setSearchList = this.setSearchList.bind(this);
    this.updateCartItems = this.updateCartItems.bind(this);
    this.updateCartCb = this.updateCartCb.bind(this);
    this.loadMoreDomains = this.loadMoreDomains.bind(this);
  }

  componentDidMount() {
    if(this.props.sld && this.props.tabs) {
      let tlds = Object.values(this.props.tabs)[0]

      this.setState({search_tlds: tlds}, () => {
        this.runSearch(this.props.sld)
      });
    }

    $.get('/cart.json').done((data) => {
      this.updateCartCb(data);
    }).fail(() => {
      this.setState({cart_items: null});
    })
  }

  tabChange(new_tlds) {
    console.log('changed tab, new tlds: ', new_tlds)

    this.setState({search_tlds: new_tlds}, () => {
      this.runSearch(this.state.searchText, true);
    });
  }

  updateCartItems(items) {
    this.setState({cart_items: items});
  }

  loadMoreDomains(e) {
    e.preventDefault();

    this.setState((prevState) => {
      let search_domains = prevState.searchList;
      let more_domains = prevState.more_domains

      let add = more_domains.splice(0, 10);
      this.load_in_batches(add);
      search_domains = search_domains.concat(add);

      return {searchList: search_domains, more_domains: more_domains};
    });
  }

  setSearchList(list) {
    let search_domains = list.splice(0, 10)

    this.setState({searchList: search_domains, more_domains: list}, () => this.load_in_batches(search_domains));
  }

  load_in_batches(add_list) {
    let ws = [];
    for(let fqdn of add_list) {
      if(this.state.results[fqdn])
        continue;
      ws.push(fqdn);

      if(ws.length > 9) {
        $.get('/domain-check/availability', {names: ws}).done((data) => this.mergeResults(data.availability));
        ws = [];
      }
    }

    if(ws.length > 0)
      $.get('/domain-check/availability', {names: ws}).done((data) => this.mergeResults(data.availability));
  }

  runSearch(search_text, ignore_text_tld = false) {
    this.setState({searchText: search_text});

    let lines = search_text.split("\n").map((l) => (l.toLowerCase().trim()))
    let tmp_list = [];
    let tlds = Object.keys(this.props.prices);

    for(let line of lines) {
        if(line == '') {
          // do nothing
          continue
        } else if(line.indexOf('.') !== -1) { // has tld
          let {sld, tld} = split_fqdn(line, tlds);

          if(ignore_text_tld) {
            // this is a tab change, ignore tlds and use new tlds
            let tab_domains = this.state.search_tlds.map((tld) => ([sanitize_sld(sld), tld].join('.')));
            tmp_list = tmp_list.concat(tab_domains);
          } else {
            tmp_list.push([sanitize_sld(sld), sanitize_sld(tld)].join('.'))
          }
        } else {
          let tab_domains = this.state.search_tlds.map((tld) => ([sanitize_sld(line), tld].join('.')));
          tmp_list = tmp_list.concat(tab_domains);
        }
    }

    // let sld = sanitize_sld(search_text);
    // let searchList = this.state.search_tlds.map((tld, index) => ([sld, tld].join('.')));

    this.setSearchList(tmp_list);
  }

  mergeResults(results) {
    this.setState((prevState) => {
      let x = prevState.results || {};
      return {results: Object.assign(x, results)}
    });
  }

  updateCartCb(data) {
    let cart_domains = [];
    for(let item of data.items) {
      if(item.orderable_type == 'domain')
        cart_domains.push([item.sld, item.tld].join("."))
      else if(item.orderable_type == 'shared_hosting_offer')
        for(let domain of item.domains) {
          let [fqdn] = domain;
          cart_domains.push(fqdn);
        }
    }
    this.setState({cart_items: data.items, cart_total: data.total, cart_domains: cart_domains,
                   inclusive_domains_used: data.inclusive_domains_used + this.props.used_inclusive_domains});
  }

  getInclusiveDomains = () => {
    const items = (this.state.cart_items || [])
    const shared_hosting_domains = items.filter((item) => (item.orderable_type == 'shared_hosting_offer')).map((item) => (item.used_domains.map((domain) => (domain[0])))).flat(1)
    const inclusive_domains = items.filter((item) => (item.orderable_type == 'domain' && item.inclusive_domain)).map((item) => (item.sld + '.' + item.tld));

    return shared_hosting_domains.concat(inclusive_domains);
  }

  render() {
    const inclusive_domain_list = this.getInclusiveDomains();

    return <div id="domain_check">
        <DomainInputContainer runSearch={this.runSearch} initial_sld={this.props.sld} />
        <div className="row">
          <div className="col-md-8">

            <TabPaneContainer onChange={this.tabChange} tabs={this.props.tabs} />

           <ResultsList inclusive_remaining={this.props.inclusive_domains - this.state.inclusive_domains_used}
                         inclusive_tlds={this.props.inclusive_tlds}
                         inclusive_domains_used={this.state.inclusive_domains_used}
                         inclusive_domain_list={inclusive_domain_list}
                         prices={this.props.prices}
                         searchList={this.state.searchList}
                         results={this.state.results}
                         cart_domains={this.state.cart_domains}
                         updateCartCb={this.updateCartCb}
                         subscription_id={this.props.inclusive_domains > this.state.inclusive_domains_used ? this.props.subscription_id : null} />

            <MoreDomains more_domains={this.state.more_domains} loadMoreDomains={this.loadMoreDomains} />
          </div>
          <div className="col-md-4">
            <SideCart loading={this.state.cart_items === null} total={this.state.cart_total} items={this.state.cart_items}
                         inclusive_domains={this.props.inclusive_domains}
                         inclusive_tlds={this.props.inclusive_tlds}
                         inclusive_domains_used={this.state.inclusive_domains_used}
                         updateCartCb={this.updateCartCb} />
          </div>
        </div>
        {this.state.cart_items && <CartItemCountPortal itemCount={this.state.cart_items.length} />}
        </div>;
  }
}

DomainCheckContainer.propTypes = {
  subscription_id: PropTypes.number,
  prices: PropTypes.object.isRequired,
  inclusive_domains: PropTypes.number,
  used_inclusive_domains: PropTypes.number,
  inclusive_tlds: PropTypes.array
}

export default DomainCheckContainer;
