import {StringInput, GenericReactForm, SubmitButton, FormActions} from 'client/js/util/form_utils';
import {PageHeader} from 'client/js/util/layout_utils';
import Callout from 'client/js/util/callout';

class Page extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.dispatch = this.dispatch.bind(this);
  }

  dispatch(action, original_event, data) {
    if(action == 'form:success')
      this.setState({submit: true});
  }

  render() {
    if(this.state.submit)
      return <Callout calloutClass="success" title="Passwort geändert" text="Das Passwort wurde erfolgreich geändert." />;

    return <div>
      <PageHeader text="Postfach-Passwort ändern" />
      <GenericReactForm namespace="change_password" url={location.pathname} dispatch={this.dispatch}>
        <StringInput required name="email" type="email" label="E-Mail-Adresse" hint="Die E-Mail-Adresse für das Postfach, dessen Passwort geändert werden soll." />
        <StringInput required name="old_password" type="password" label="bisheriges Passwort"/>
        <StringInput required name="new_password" type="password" label="neues Passwort"/>

        <FormActions>
          <SubmitButton icon="pencil" text="Passwort ändern" />
        </FormActions>
      </GenericReactForm></div>;
  }
}

export default Page;
