import PropTypes from 'prop-types';
import Modal from 'react-modal';
import {FaIcon} from 'client/js/util/layout_utils';
import {StripeProvider, injectStripe, Elements} from 'react-stripe-elements';

const xhrToError = (xhr) => {
  if(xhr.status != 400)
    return `Fehler ${xhr.status}`;

  const response = JSON.parse(xhr.responseText);
  return [i18n_t(`cc_errors.${response.error.code}`), `(${response.error.message})`].join(' ');
}

const handleAction = (stripe, client_secret, payment_method) => {
  return new Promise((resolve, reject) => {
    stripe.handleCardPayment(
      client_secret,
      payment_method
    ).then((result) => {
      if (result.error) {
        reject(result.error.message);
      } else {
        resolve(result);
      }
    });
  });
}

class ApproveForm extends React.Component {
  state = {}

  handlePayment = (e) => {
    e.preventDefault();
    this.setState({submitting: true});

    handleAction(this.props.stripe, this.props.client_secret, {payment_method: this.props.payment_method}).then(
      () => this.props.setSuccess()
    ).catch(
      (error) => {
        this.setState({form_error: error})
        this.setState({submitting: false});
      }
    );
  }

  render() {
    return <div>
          <div className="modal-body">
            <p>Der Betrag von {format_price(this.props.amount)} ist derzeit noch offen, da die 2-Faktor-Authentifizierung aussteht. Bitte klicke den Button, um den Betrag für die folgende Leistung freizugeben:</p>
            <p className="text-center">
              &quot;{this.props.text}&quot;
            </p>

            {!!this.state.form_error && <p className="text-center text-danger">
              Fehler: {this.state.form_error}
            </p>}
          </div>

          <div className="modal-footer">
            <button className="btn btn-primary" onClick={this.handlePayment} disabled={!!this.state.submitting}><FaIcon name="euro" /> {format_price(this.props.amount)} freigeben</button>
          </div>
        </div>;
  }
}

ApproveForm.propTypes = {
  client_secret: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  setSuccess: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  payment_method: PropTypes.string.isRequired,
  stripe: PropTypes.object.isRequired,
}

const InjectedApproveForm = injectStripe(ApproveForm);

class ModalContent extends React.Component {
  state = {stripe: null, success: this.props.success}

  componentDidMount() {
    if(typeof(window.Stripe) === 'undefined')
      $.getScript('https://js.stripe.com/v3/', () => {
        this.setState({stripe: window.Stripe(window.StripePublishableKey)});
      });
    else
      this.setState({stripe: window.Stripe(window.StripePublishableKey)});
  }

  setSuccess = () => {
    this.setState({success: true});
  }

  render() {
    if(this.state.success) {
      return <>
          <div className="modal-body">
            <div className="text-center">
              <h4 className="text-success">
                <FaIcon name="thumbs-o-up fa-3x mb-3" />
                <br/>
                Zahlung erfolgreich!
              </h4>

              <p className="mb-3">
                Vielen Dank! Die Zahlung wurde erfolgreich autorisiert.
              </p>
            </div>
          </div>
        </>;
    }

    return <StripeProvider stripe={this.state.stripe}>
              <Elements>
                <InjectedApproveForm
                      client_secret={this.props.payment_intent_client_secret}
                      amount={this.props.amount}
                      text={this.props.text}
                      payment_method={this.props.payment_method}
                      setSuccess={this.setSuccess} />
              </Elements>
            </StripeProvider>;
  }
}

ModalContent.propTypes = {
  success: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  payment_method: PropTypes.string.isRequired,
  payment_intent_client_secret: PropTypes.string.isRequired,
}

const ApprovePaymentIntent = (props) => (
  <Modal isOpen className="modal fade in show d-block" contentLabel="Zahlung freigeben">
    <div className="modal-dialog modal-dialog-centered" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Zahlung freigeben</h5>
        </div>

        <ModalContent {...props} />
      </div>
    </div>
  </Modal>
)

ApprovePaymentIntent.propTypes = {
  success: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  payment_method: PropTypes.string.isRequired,
  payment_intent_client_secret: PropTypes.string.isRequired,
}

export default ApprovePaymentIntent;
