import { FaIcon } from 'client/js/util/layout_utils';
import PropTypes from "prop-types";
import { useState } from 'react';

const RemoveFromCart = ({ item_id, updateCartCb }) => {
  const [working, setWorking] = useState(false);

  const removeFromCart = (e) => {
    e.preventDefault();
    setWorking(true);

    $.get('/cart/remove_item.json', { id: item_id })
      .done((data) => {
        setWorking(false);
        updateCartCb(data);
      })
      .fail(() => setWorking(false));
  };

  if (working) {
    return <FaIcon name="spin fa-spinner" />;
  }

  return (
    <a onClick={removeFromCart} href={`/cart/remove_item?id=${item_id}`}>
      <FaIcon name="trash" />
    </a>
  );
};

RemoveFromCart.propTypes = {
  updateCartCb: PropTypes.func.isRequired,
  item_id: PropTypes.number.isRequired
};

class ChangeQuantityButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {working: false};

    this.changeQuantity = this.changeQuantity.bind(this);
  }

  changeQuantity(e) {
    e.preventDefault();
    this.setState({working: true});

    $.post('/cart.json', {quantity: {[this.props.item_id]: this.props.quantity + (this.props.type == 'plus' ? 1 : -1)}}
    ).done((data) => {
      this.setState({working: false});
      this.props.updateCartCb(data);
    }).error((data) => this.setState({working: false}))
  }

  render() {
    if(this.state.working)
      return <FaIcon name="spin fa-spinner" />;

    return <a onClick={this.changeQuantity} href={`/cart`}><i className={`fa fa-${this.props.type}`}></i></a>;
  }
}

ChangeQuantityButton.propTypes = {
  updateCartCb: PropTypes.func.isRequired,
  item_id: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired
};

const Adjustments = (props) => {
  if(!props.adjustments.length)
    return null;

  return <section className="adjustments">
          {props.adjustments.map((adjustment) => {
            return <div key={adjustment.text}>{adjustment.text} <span className="price">{format_price(adjustment.amount*100)}</span></div>
          })}
         </section>;
}

Adjustments.propTypes = {
  adjustments: PropTypes.array
};

const SharedHostingItem = function(props) {
  if(props.domains && props.domains.length > 0)
      return <li className="list-group-item">
           <strong>{props.name}</strong> <RemoveFromCart updateCartCb={props.updateCartCb} item_id={props.id} />
           <span className="price">{format_price(props.amount*100)}</span>
           <div className="item-description">
             {props.inclusive_domains} {pluralize(props.inclusive_domains, 'Domain', 'Domains')} inklusive ({props.inclusive_tlds.join(', ')})
             <br/>
             Ausgewählt:
             <br/>
             <ul>{props.domains.map((item) => {
                let [fqdn, mode] = item;
                return <li key={fqdn}>{fqdn} ({mode == 'CREATE' ? 'Registrierung' : 'Transfer'})</li>;
               })}</ul>
           </div>
         </li>;

  return <li className="list-group-item">
           <strong>{props.name}</strong> <RemoveFromCart updateCartCb={props.updateCartCb} item_id={props.id} />
           <span className="price">{format_price(props.amount*100)}</span>
           <div className="item-description">
             {props.inclusive_domains} Domains inklusive ({props.inclusive_tlds.join(', ')})
             <br/>
             Noch keine Domains ausgewählt.
           </div>
         </li>;
}

const DomainItem = function(props) {
  return <li className="list-group-item">
            <strong>{props.sld}.{props.tld}</strong> <RemoveFromCart updateCartCb={props.updateCartCb} item_id={props.id} /> <span className="price">{format_price(props.amount*100)}</span>
            <br/>
            {props.mode == 'CREATE' ? 'Domain-Registrierung' : 'Domain-Transfer'}
            {' '}
            {props.premium_domain ? '(Premium-Domain)' : null}
            <br/>
            {props.inclusive_domain ? ' Inklusiv-Domain' :
              <span className="quantity">
                <ChangeQuantityButton updateCartCb={props.updateCartCb} type="minus" item_id={props.id} quantity={props.quantity} />
                {' '}
                {props.quantity}
                {' '}
                <ChangeQuantityButton updateCartCb={props.updateCartCb} type="plus" item_id={props.id} quantity={props.quantity} />
                {' '}
                {pluralize(props.quantity, 'Jahr', 'Jahre')}
              </span>}
            <Adjustments adjustments={props.adjustments} />
            </li>;
}

DomainItem.propTypes = {
  adjustments: PropTypes.array.isRequired,
  id: PropTypes.number.isRequired,
  mode: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  amount: PropTypes.number.isRequired,
  sld: PropTypes.string.isRequired,
  tld: PropTypes.string.isRequired,
  inclusive_domain: PropTypes.bool.isRequired,
  updateCartCb: PropTypes.func.isRequired
}

const CartItem = function(props) {
  if(props.orderable_type == 'shared_hosting_offer')
    return <SharedHostingItem updateCartCb={props.updateCartCb} {...props}/>

  if(props.orderable_type == 'domain')
    return <DomainItem updateCartCb={props.updateCartCb} {...props}/>

  return <li className="list-group-item"><strong>{props.name}</strong> <RemoveFromCart updateCartCb={props.updateCartCb} item_id={props.id} /> <span className="price">{format_price(props.amount*100)}</span><br/><Adjustments adjustments={props.adjustments} /></li>;
}

CartItem.propTypes = {
  adjustments: PropTypes.array.isRequired,
  updateCartCb: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  orderable_type: PropTypes.string.isRequired
}

const DomainCountInfo = (props) =>  {
  if(props.inclusive_domains > 0) {
    return <div>{props.inclusive_domains_used}/{props.inclusive_domains} Inklusiv-Domains genutzt</div>;
  }

  return null;
}

CartItem.propTypes = {
  inclusive_domains_used: PropTypes.number,
  inclusive_domains: PropTypes.number
}

const SideCart = function(props) {
   if(props.loading)
    return <section className='card side-cart'>
            <h5 className="card-header">Warenkorb</h5>

            <div className="card-body">
              <div className="css-spinloader"></div>
            </div>
           </section>;

  if(!props.items || props.items.length == 0)
    return <section className='card side-cart'>
            <h5 className="card-header">Warenkorb</h5>

            <div className="card-body">
              Keine Artikel im Warenkorb.
            </div>
           </section>;

  return <aside className='card side-cart'>
            <h5 className="card-header">Warenkorb</h5>
            <ul className="list-group list-group-flush">
              {props.items.map((item) => <CartItem key={item.id} updateCartCb={props.updateCartCb} {...item} />)}
            </ul>

            <div className="card-body">
              <p className="card-text">
                <strong>Summe: <span className="price">{format_price(props.total*100)}</span></strong>
                <DomainCountInfo inclusive_domains_used={props.inclusive_domains_used} inclusive_domains={props.inclusive_domains} />
              </p>

              <a href="/cart" className="btn btn-secondary mb-3">zum Warenkorb →</a>

              <a href="/checkout" className="btn btn-primary">bestellen</a>
            </div>
           </aside>;
}

SideCart.propTypes = {
  loading: PropTypes.bool.isRequired,
  updateCartCb: PropTypes.func.isRequired,
  items: PropTypes.array,
  total: PropTypes.number
}

export default SideCart;
